@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  overflow-y: scroll;
  overflow-x: hidden;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply text-gray-800 bg-slate-200;
}

@layer base {
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button,
  input[type='number'] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }
}

@layer components {
  .container {
    @apply lg:max-w-screen-lg;
  }
}

p {
  @apply text-gray-600 text-sm; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  @apply text-3xl font-medium;
}
h2 {
  @apply text-2xl font-medium;
}
h3 {
  @apply text-lg font-medium;
}
